import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, MenuItem, TextField} from "@material-ui/core";
import { useStyles } from '../../styles/useStyles'
import { db } from '../../services/firebase'


function FormEdit(props) {

    const { week, currentId, handleClose, } = props

    const classes = useStyles();

    const capacities = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'
    ]

    const [values, setValues] = useState(null);

    const getDataValue = async (currentId) => {
        const reservedData = await db
            .collection( 'Bookings' )
            .doc(currentId).get();
        setValues(reservedData.data());
    }

    const setDataValue = async (value) => {
         await db.collection( 'Bookings' )
             .doc(currentId)
             .update(value);
    }

    const handleChange = async (e) =>  {
        const {name, value} = e.target;
        setValues({...values, [name]: value});

    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setDataValue(values);
        handleClose()
    };

    useEffect(() => {
        getDataValue(currentId)
    }, [])

    if (values === null) {
        return (
            <div className={classes.root}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                    <Box>
                        <CircularProgress className={classes.progressCircular}/>
                    </Box>
                </Box>
            </div>
        )
    }

    const selectDay = values.date
    const dSelectDay = new Date( `${selectDay}T00:00:00` )
    const weekDay = dSelectDay.getDay()
    const times = week[weekDay]

    return (
        <>
            <form noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Fecha de reserva"
                    name="date"
                    type="date"
                    value={values.date}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Hora de la reserva"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    select
                    name="time"
                    value={values.time}
                    onChange={handleChange}
                >
                    {times.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Numero de Personas"
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    select
                    name="capacity"
                    value={values.capacity}
                    onChange={handleChange}
                >
                    {capacities.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nombre completo"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    autoComplete="name"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Telefono"
                    name="phone"
                    type="tel"
                    value={values.phone}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    { currentId == null ? 'Reservar' : 'guardar' }
                </Button>
            </form>
        </>
    );
}

export default FormEdit;
import React  from 'react';
import {Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import * as path from './paths'

// Route Component Page Path
import Dashboard from "../pages/dashboard"
import SignIn from "../pages/signin/signin"
import SignUp from "../pages/signin/signup"
import Forgot from "../pages/signin/forgot"
import AccountDetail from "../pages/account/details";
import PaymentDetails from "../pages/account/payment";


export default function RouterConfig () {

    return (
        <Switch>
            <Route path={path.HomePath} exact component={SignIn} />
            <Route path={path.LoginPath} exact component={SignIn} />
            <Route path={path.SignupPath} exact component={SignUp} />
            <Route path={path.ForgotPasswordPath} exact component={Forgot} />
            <PrivateRoute path={path.DashboardPath} exact component={Dashboard} />
            <PrivateRoute path={path.AccountDetailsPath} exact component={AccountDetail} />
            <PrivateRoute path={path.PaymentDetailsPath} exact component={PaymentDetails} />
        </Switch>
    )};
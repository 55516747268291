const initialState = {
    userId: null,
};

const reducer = (state, action) => {
    switch (action.type) {

        case 'setUserId':
            return {
                userId: action.payload
            }

        default:
            throw new Error();
    }
}

export { initialState, reducer }
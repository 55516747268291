import React, {createContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { auth } from "../services/firebase"
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "100%",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        display: 'flex',
        '& > * + *': {
            margin: theme.spacing(2),
        },
    },
    progressCircular: {
        display: "flex",
        alignItems: "center"
    }
}));


export const AuthContext = createContext( {user: null} );

export const AuthProvider = ({children}) => {

    const classes = useStyles();

    const [currentUser, setCurrentUser] = useState( null );

    const [pending, setPending] = useState( true );

    useEffect( () => {
        auth.onAuthStateChanged( (user) => {
            setCurrentUser( user );
            setPending( false )
        } );
    }, [] );


    if (pending) {
        return (
            <div className={classes.root}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                    <Box>
                        <CircularProgress className={classes.progressCircular}/>
                    </Box>
                </Box>
            </div>
        )
    }

    return (
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    );
};
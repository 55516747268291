import React, {useEffect, useReducer, useState} from 'react';
import {
    Table,
    TableBody,
    TableRow,
    IconButton,
    Button,
    Grid,
    Typography,
    makeStyles,
    Drawer,
    Collapse,
    TableCell, Card, CardContent, CardActions, Container
} from "@material-ui/core";
import {Alert, AlertTitle} from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {db} from "../../../services/firebase";
import {LoginInitialState, loginReducer} from '../../../reducer/reducer/paymentReducer';
import FormPayment from './formPayments';
import { Elements, } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import Box from "@material-ui/core/Box";
const stripe = loadStripe('pk_live_51IZrgPEK6v4UtvqqcTZOwUHRV2L15s2NeIYiG9UDjvVqtB2J2p2zarjkzL88KjclgFQxncTgxH1G6XL6qwqiexTD0031WI2wSI');

const useStyles = makeStyles( (theme) => ({

    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing( 8, 0, 6 ),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 3 ),
    },
    submit: {
        margin: theme.spacing( 1, 0, 3 ),
    },
}) );

export default function AccountDetails({userId}) {

    const prices = [
        {
            title: 'Startup',
            price: '39',
            description: [
                '',
            ],
            buttonText: 'Select',
            buttonVariant: 'outlined',
            priceMonthly: 'price_1J8HuaEK6v4UtvqqO1kvsrLS'
        },
        {
            title: 'Growth',
            subheader: 'Most popular',
            price: '99',
            description: [
                '',
            ],
            buttonText: 'Select',
            buttonVariant: 'contained',
            priceMonthly: 'price_1J8HvfEK6v4UtvqqKZHlSqsr'
        },
        {
            title: 'Team',
            price: '199',
            description: [
                '',
            ],
            buttonText: 'Select',
            buttonVariant: 'outlined',
            priceMonthly: 'price_1J8HwDEK6v4UtvqqqbFAKYrL'
        },
    ];

    const classes = useStyles();

    const [state, dispatch] = useReducer( loginReducer, LoginInitialState )
    const [cards, setCards] = useState([])

    const MerchantDB = db.collection( "merchant" )

    const addOrEditValues = async (docs) => {
        const linkObject = docs[0]
        try {
            await MerchantDB.doc( userId ).update( linkObject );
            await MerchantDB.doc( userId ).collection('tokens').add({token: linkObject.token});
            dispatch( {
                type: "success",
                payload: linkObject
            } );
            setTimeout(
                () => dispatch( { type: 'closeAlert'} ),
                3000
            );
        } catch (error) {
            console.error( error );
        }
    };

    const setDefaultCard = async (idCard) => {
        await MerchantDB.doc( userId ).update( {default_source: idCard} );
    }

    const deleteCard = async (id) => {
        await MerchantDB.doc( userId ).collection('sources').doc(id).delete();
    }

    const setPricePlan = async (pricePlan) => {
        await MerchantDB.doc( userId ).collection('subscription').doc().set({price: pricePlan});
        console.log(pricePlan)
    }


    useEffect( () => {
        MerchantDB
            .doc( userId )
            .get().then( (doc) => {
            if (doc.exists) {
                dispatch( {type: 'getData', payload: doc.data()} );
            }
        } ).catch( (error) => {
            dispatch( {type: 'getDataError'} )
        } );
    }, [] );

    useEffect( () => {
        MerchantDB
            .doc( userId )
            .collection( "sources" )
            .onSnapshot( (querySnapshot) => {
                const docs = []
                querySnapshot.forEach( doc => {
                    docs.push( {...doc.data(), idCard: doc.id} )
                } );
                setCards( docs )
            } );
    }, [] );


    return (
        <React.Fragment>
            <Collapse in={state.openAlert}>
                <Alert
                    severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => dispatch( {type: 'closeAlert'} )}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    <AlertTitle>Edición Exitosa</AlertTitle>
                    Sus datos han sido editados <strong>exitosamente</strong>
                </Alert>
            </Collapse>
            <Box component="section" p={2} mt={5}>
                <Typography component="h3" variant="h4" align="left" color="textPrimary" gutterBottom>
                    Billing Contact Information
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Restaurant Name:</TableCell>
                                    <TableCell>{state.data.description}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Card Holder Name:</TableCell>
                                    <TableCell>{state.data.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Phone:</TableCell>
                                    <TableCell>{state.data.phone}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Address:</TableCell>
                                    <TableCell>{state.data.line1}, {state.data.line2}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>City, State:</TableCell>
                                    <TableCell>{state.data.city}, {state.data.state}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Postal Code:</TableCell>
                                    <TableCell>{state.data.postal_code}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Country:</TableCell>
                                    <TableCell>{state.data.state}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Pais:</TableCell>
                                    <TableCell>{state.data.country}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Button
                    onClick={() => dispatch( {type: 'openEdit'} )}
                    variant="contained"
                    color="secondary"
                    className={classes.submit}>
                    Editar
                </Button>
                <Drawer anchor="right" open={state.open} onClose={() => dispatch( {type: 'closeEdit'} )}>
                    <Elements stripe={stripe}>
                        <FormPayment data={state.data} {...{addOrEditValues, dispatch, stripe}}/>
                    </Elements>
                </Drawer>
            </Box>
            <Box component="section" p={2} mt={5}>
                <Typography component="h3" variant="h4" align="left" color="textPrimary" gutterBottom>
                    Payment methods
                </Typography>
                <Grid container spacing={4}>
                    {cards.map( (card) => (
                        <Grid item key={card.idCard} xs={12} sm={6} md={4}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {card.funding}
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        {card.brand}
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        {card.brand === "American Express" ? "**** ****** *" : "**** **** ****"} {card.last4}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        exp. {card.exp_month}/{card.exp_year}
                                    </Typography>
                                </CardContent>
                                <CardActions align="center">
                                    <Button fullWidth variant="outlined" color="secondary"
                                            onClick={() => deleteCard( card.idCard )}>
                                        Remove
                                    </Button>
                                    <Button fullWidth variant="contained" color="secondary"
                                            onClick={() => setDefaultCard( card.id )}>
                                        Default
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ) )}
                </Grid>
            </Box>
            <Box component="section" p={2} mt={5}>
                <Typography component="h2" variant="h4" align="left" color="textPrimary" gutterBottom>
                    Subscriptions and Services
                </Typography>
                <Grid container spacing={5} alignItems="flex-start">
                    {prices.map( (price) => (
                        <Grid item key={price.title} xs={12} sm={6} md={4}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h3" component="h3" align="center" color="textSecondary">
                                        {price.title}
                                    </Typography>
                                    <Typography className={classes.title} align="center" color="textSecondary"
                                                gutterBottom>
                                        {price.subheader}
                                    </Typography>
                                    <Box display="flex" justifyContent="center" alignItems="flex-end" py={1}>
                                        <Typography variant="h4" component="h3" align="center">
                                            ${price.price}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            /mo
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {price.description.map( (line) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                {line}
                                            </Typography>
                                        ) )}
                                    </ul>
                                </CardContent>
                                <CardActions align="center">
                                    <Button fullWidth variant={price.buttonVariant} color="secondary"
                                            onClick={() => setPricePlan( price.priceMonthly )}>
                                        {price.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ) )}
                </Grid>
            </Box>
        </React.Fragment>
    );
}

import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Container, AppBar, Tab, Tabs, Box, CircularProgress, Paper} from '@material-ui/core';
import {TabPanel, a11yProps} from '../../components/TabPanel'
import {useStyles} from '../../styles/useStyles'
import LeftMenu from '../../components/leftmenu'
import Header from '../../components/header'
import Footer from "../../components/footer";
import Orders from './Orders';
import {AuthContext} from "../../router/AuthContext";
import {initialState, reducer} from "../../reducer/reducer/userReducer";

export default function Dashboard() {
  const classes = useStyles();
  const [value, setValue] = useState( 'present' );

  const {currentUser: {uid}} = useContext( AuthContext );
  // const uid = 'oiSKM7d2mCMsU2dFb1Gt4utkHPd2'
  const [state, dispatch] = useReducer( reducer, initialState );

  useEffect( () => {
    dispatch( {
      type: 'setUserId',
      payload: uid,
    } )
  }, [uid] )

  if (state.userId === null) {
    return (
        <div className={classes.root}>
          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Box>
              <CircularProgress className={classes.progressCircular}/>
            </Box>
          </Box>
        </div>
    )
  }

  const handleChange = (event, newValue) => {
    setValue( newValue );
  };

  return (
      <main className={classes.root}>
        <LeftMenu/>
        <div className={classes.content}>
          <Header/>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth="lg" className={classes.container}>
            <AppBar position="static" color="secondary">
              <Tabs value={value} onChange={handleChange} TabIndicatorProps={{style: {height: "5px"}}}
                    indicatorColor="primary">
                <Tab value="past" label="Pasado" {...a11yProps( 'past' )}/>
                <Tab value="present" label="Presente" {...a11yProps( 'present' )} />
              </Tabs>
            </AppBar>
            <Paper className={classes.paper}>
              <TabPanel value={value} index="past">
                <Orders userId={state.userId} operator={'<'}/>
              </TabPanel>
              <TabPanel value={value} index="present">
                <Orders userId={state.userId} operator={'>='}/>
              </TabPanel>
            </Paper>
          </Container>
          <Footer/>
        </div>
      </main>
  );
}

import React, { useState } from 'react';

import {
    Container,
    Grid,
    Button,
    Typography,
    Avatar,
    TextField,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Checkbox, makeStyles,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { db } from '../services/firebase'

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    avatar: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
    BtnRadius: {
        borderRadius: '30px',
    },
    WhiteBtn: {
        padding: '12px 30px',
        fontWeight: '700',
    },
    animatedBtn: {
        transition: 'all .25s ease-in-out',
        '&:hover': {
            transform: 'scale(1) translateY(-4px) translateZ(20px)',
        },
    },
}));

export default function ContactForm() {

    const classes = useStyles();

    const initialStateValues = {
        name: '',
        email: '',
        message: '',
        checkbox: '',
    };



    const [values, setValues] = useState(initialStateValues);

    const handleInputChange = (e) => {
        const { name, value} = e.target;
        setValues({...values, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await db.collection('Prospects').doc().set(values);
        } catch(error) {
            console.error(error)
        } setValues({...initialStateValues})
    };


    return (
        <Container component="main" maxWidth="xs">
            <DialogTitle id="form-dialog-title">
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={2} sm={2}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs={10} sm={10}>
                        <Typography component="h1" variant="h5">
                            Formulario de Contacto
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField onChange={handleInputChange} value={values.name} autoComplete="name" name="name" id="name" variant="outlined" required fullWidth label="Nombre Completo" autoFocus/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={handleInputChange} value={values.email} autoComplete="email" name="email" id="email" variant="outlined" required fullWidth label="Correo Electronico" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={handleInputChange} value={values.message} type="text" name="message" id="message" variant="outlined" required fullWidth label="Mensaje" multiline rows={5}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel onChange={handleInputChange} value={values.checkbox} name="checkbox" control={<Checkbox value="true" color="primary" />} label="Quiero recibir inspiración, promociones de marketing y actualizaciones por correo electrónico."/>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="secondary" selected className={`${classes.WhiteBtn} ${classes.BtnRadius} ${classes.animatedBtn}`} >
                            Enviar
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Container>
    );
}


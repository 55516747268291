// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCrGOlT7ET4qEHutTwWG_D7O9nFormWP0w",
    authDomain: "myappointment-dev.firebaseapp.com",
    projectId: "myappointment-dev",
    storageBucket: "myappointment-dev.appspot.com",
    messagingSenderId: "656230455577",
    appId: "1:656230455577:web:75777587523b6a6b574d88",
    measurementId: "G-Y1266F9E3S"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const analytics = firebase.analytics();
export const auth = firebase.auth()
import { useState, useEffect } from "react";
import {db} from "../services/firebase";

const GetBookings = (id, operator) => {
    const [bookings, setBookings] = useState([]);
    const [date,setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 60000 )
        return function cleanup() {
            clearInterval(timer)
        }
    });
    const d = date.getTime()
    const y = d - 29*60*60*1000;
    const dateString = new Date(y)
    const moment = dateString.toISOString()

    useEffect( () => {
        db.collection( 'Bookings' )
            .where( 'userId', '==', id )
            .orderBy( 'date' )
            .orderBy( 'time' )
            .where( 'date', operator, moment )
            .onSnapshot( (QuerySnapshot) => {
                const docs = [];
                QuerySnapshot.forEach( doc => {
                    docs.push( {...doc.data(), id: doc.id} )
                } );
                setBookings( docs );
            } );
    }, [id, operator])

    return bookings

};

export default GetBookings;
import { useState, useEffect } from "react";
import {db} from "../services/firebase";

const GetCustomer = (id, collection) => {
    const [customer, setCustomer] = useState([]);

    useEffect(async () => {
        await db.collection( collection )
            .doc( id )
            .get().then((doc) => {
                if (doc.exists) {
                    setCustomer(doc.data())
                } else {
                    db.collection(collection)
                        .doc(id)
                        .set({
                            capacity: 100,
                            description: "",
                            emailFrom: "My Appointment <reserve@myappointment.dev>",
                            emailMessage: "Gracias por escoger My Appointment",
                            country: "",
                            locality: "",
                            postal_code: "",
                            region: "",
                            street_address: "",
                            latitude: 1,
                            longitude: 1,
                            profileCoverPhoto: "https://myappointment.dev/static/media/header-bg-1.45cb342c.jpg",
                            profilePhoto: "https://myappointment.dev/logo_256.png",
                            restaurantName: "Edit Info in lateral menu",
                            schedule: "",
                            subdomain: id,
                            telephone: "",
                            week: {
                                0: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00"],
                                1: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00"],
                                2: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00"],
                                3: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00"],
                                4: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00"],
                                5: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00"],
                                6: ["11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00"]
                            },
                            url: "",
                            urlMenu: ""
                        })
                }
            });
    }, [id, collection])

    return customer

};

export default GetCustomer;
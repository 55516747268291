// Route Path
export const HomePath = '/';
export const LoginPath = '/signin';
export const SignupPath = '/signup';
export const ForgotPasswordPath = '/forgot-password';
export const PagePath = '/p/:subdomain'

export const DashboardPath = '/dashboard';
export const AccountDetailsPath = '/account/details';
export const PaymentDetailsPath = '/account/Payment';

export const BookPath = '/book';
export const PricesPath = '/Prices';


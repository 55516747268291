import React, {useState} from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import countries from "../countries.json"

// stripe
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from "@stripe/react-stripe-js";
import StripeInput from'./StripeInput'


const useStyles = makeStyles( (theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing( 6, 10, 6 ),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing( 3 ),
    },
    textarea: {
        width: "100%",
        padding: theme.spacing( 2.3125, 1.75 ),
        fontSize: '1rem',
        fontFamily: 'roboto,sans',
    },
    submit: {
        margin: theme.spacing( 3, 0, 2 ),
    },
}) );

export default function EditAccountDetails({data, addOrEditValues, dispatch}) {

    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();


    const [values, setValues] = useState( data );

    const handleChange = e => {
        const {name, value} = e.target;
        setValues( {...values, [name]: value} );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const docs = []
        if (!stripe || !elements) {
            return;
        }
        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            console.log(result.error.message);
        } else {
            stripeTokenHandler(result.token);
        }
        function stripeTokenHandler(token) {
            docs.push( {...values, token: token.id});
        }

        addOrEditValues( docs )
    };


    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Grid container justify="space-between" alignItems="center" spacing={3}>
                    <Grid item>
                        <Typography component="h3" variant="h5" align="left" color="textPrimary" gutterBottom>
                            Edit Billing Contact
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => dispatch( {type: 'closeEdit'} )} color="primary">
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Contact information</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Card Holder Name"
                                value={values.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                name="description"
                                variant="outlined"
                                fullWidth
                                id="description"
                                label="Restaurant Name"
                                value={values.description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="Phone"
                                variant="outlined"
                                fullWidth
                                id="phone"
                                label="phone"
                                value={values.phone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="line1"
                                variant="outlined"
                                required
                                fullWidth
                                id="line1"
                                label="Address line 1"
                                value={values.line1}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                name="line2"
                                variant="outlined"
                                fullWidth
                                id="line2"
                                label="Address line 2"
                                value={values.line2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="City"
                                name="city"
                                variant="outlined"
                                required
                                fullWidth
                                value={values.city}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="State"
                                name="state"
                                variant="outlined"
                                fullWidth
                                value={values.state}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="ZIP"
                                name="postal_code"
                                variant="outlined"
                                fullWidth
                                value={values.postal_code}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Country"
                                name="country"
                                variant="outlined"
                                fullWidth
                                select
                                value={values.country}
                                onChange={handleChange}
                            >
                                {countries.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Credit Card Number"
                                name="number"
                                variant="outlined"
                                required
                                fullWidth
                                InputProps={{
                                    inputComponent: StripeInput,
                                    inputProps: {
                                        component: CardNumberElement
                                    },
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                label="Expiration Date"
                                name="exp"
                                variant="outlined"
                                required
                                fullWidth
                                InputProps={{
                                    inputProps: {
                                        component: CardExpiryElement
                                    },
                                    inputComponent: StripeInput
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                label="CVC"
                                name="cvc"
                                variant="outlined"
                                required
                                fullWidth
                                InputProps={{
                                    inputProps: {
                                        component: CardCvcElement
                                    },
                                    inputComponent: StripeInput
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                    >
                        Guardar
                    </Button>
                </form>
            </Container>
        </div>
    );
}
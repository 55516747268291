import React, { useState } from 'react';
import {
  Link, Typography, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useStyles } from '../../styles/useStyles'
import getCustomer from "../../hooks/getCustomer";
import getBooking from "../../hooks/getBookings";
import FormEdit from './FormEdit'
import {db} from "../../services/firebase";


export default function Orders({userId, operator}) {

    const classes = useStyles();

    const [open, setOpen] = React.useState( false );
    const [currentId, setCurrentId] = useState( [] )

    const customer = getCustomer( userId, 'merchant_data' )
    const booking = getBooking( userId, operator )

    const handleClickOpen = id => {
        setOpen( true );
        setCurrentId( id )
    };
    const handleClickDelete = id => {
        if (window.confirm("¿Estás seguro de que deseas eliminar esta reserva?")) {
            db.collection( 'Bookings' )
                .doc( id )
                .delete();
        }
        return null
    }

    const handleClose = () => {
        setOpen( false );
    };

    return (
        <React.Fragment>
            <Typography component="h2" variant="h5" color="secondary">
                Reservas Recientes de {customer.restaurantName}
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell size="small">Fecha</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell size="small"># Personas</TableCell>
                        <TableCell size="small">Hora</TableCell>
                        <TableCell>Telefono</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {booking.map( (data) => (
                        <TableRow key={data.id}>
                            <TableCell>{data.id.toUpperCase().substring( 14 )}</TableCell>
                            <TableCell>{data.date}</TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.capacity}</TableCell>
                            <TableCell>{data.time}</TableCell>
                            <TableCell>{data.phone}</TableCell>
                            <TableCell>{data.email}</TableCell>
                            <TableCell>
                                <div>
                                    <Link className={classes.link} title="Editar"
                                          underline="none" variant="inherit" color="textPrimary"
                                          onClick={() => handleClickOpen( data.id )}>
                                        <Edit/>
                                    </Link>
                                    <Link className={classes.link} title="Eliminar"
                                          underline="none" variant="inherit" color="textPrimary"
                                          onClick={() => handleClickDelete( data.id )}>
                                        <Delete/>
                                    </Link>
                                </div>
                            </TableCell>
                        </TableRow>
                    ) )}
                </TableBody>
            </Table>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent className={classes.dialog}>
                    <FormEdit week={customer.week} {...{currentId, handleClose}}/>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}

const LoginInitialState = {
    error: '',
    cards: [],
    data: {
        name: "",
        description: "",
        phone: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
    }
}

const loginReducer = (state, action) => {
    switch (action.type) {
        case "getData":
            return {
                data: action.payload,
                error: '',
                open: false,
                openAlert: false,
            }

        case "getCards":
            return {
                cards: action.payload,
                error: ''
            }

        case "success":
            return {
                data: action.payload,
                error: '',
                open: false,
                openAlert: true,
            }

        case "openEdit":
            return {
                ...state,
                open: true
            }

        case "closeEdit":
            return {
                ...state,
                open: false,
            }

        case "closeAlert":
            return {
                ...state,
                openAlert: false,
            }

        case 'editFormValue':
            state.data[action.key.toLowerCase()] = action.value;
            return { ...state };

        case "getDataError":
            return {
                data: {},
                error: 'Something went wrong!'
            }
        default:
            return state
    }
}

export { LoginInitialState, loginReducer }
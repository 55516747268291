import React, {useContext, useEffect, useReducer} from 'react';
import { useStyles } from '../../../styles/useStyles'
import {Container, Box, CircularProgress, Paper} from '@material-ui/core';
import LeftMenu from "../../../components/leftmenu";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import AccountDetails from "./details";

import {AuthContext} from "../../../router/AuthContext";
import {initialState, reducer} from "../../../reducer/reducer/userReducer";

export default function Account() {

    const classes = useStyles();
    const {currentUser: {uid}} = useContext( AuthContext );
    const [state, dispatch] = useReducer( reducer, initialState );

    useEffect( () => {
        dispatch( {
            type: 'setUserId',
            payload: uid,
        } )
    }, [uid] )

    if (state.userId === null) {
        return (
            <div className={classes.root}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                    <Box>
                        <CircularProgress className={classes.progressCircular}/>
                    </Box>
                </Box>
            </div>
        )
    }

    return (
        <main className={classes.root}>
            <LeftMenu/>
            <div className={classes.content}>
                <Header/>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    <Paper className={classes.paper}>
                        <AccountDetails userId={state.userId}/>
                    </Paper>
                </Container>
                <Footer/>
            </div>
        </main>
    );
}

import React, { useState } from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import countries from "../countries.json";

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 10, 6),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    textarea: {
        width: "100%",
        padding: theme.spacing(2.3125, 1.75),
        fontSize: '1rem',
        fontFamily: 'roboto,sans',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function EditAccountDetails({data, addOrEditValues, dispatch}) {

    const classes = useStyles();

    const CHARACTER_LIMIT = 280;

    const [values, setValues] = useState(data);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addOrEditValues(values);
    };

    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Grid container justify="space-between" alignItems="center" spacing={3}>
                    <Grid item>
                        <Typography component="h3" variant="h5" align="left" color="textPrimary" gutterBottom>
                            Editar detalles de la Cuenta
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => dispatch( {type: 'closeEdit'} )} color="primary">
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="restaurantName"
                                variant="outlined"
                                required
                                fullWidth
                                id="restaurantName"
                                label="Nombre del Restaurante"
                                autoFocus
                                value={values.restaurantName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="telephone"
                                variant="outlined"
                                fullWidth
                                id="telephone"
                                label="Telefono"
                                value={values.telephone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="url"
                                variant="outlined"
                                fullWidth
                                id="url"
                                label="Pagina Web"
                                value={values.url}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="profileCoverPhoto"
                                variant="outlined"
                                fullWidth
                                id="profileCoverPhoto"
                                label="Cover Foto"
                                value={values.profileCoverPhoto}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="profilePhoto"
                                variant="outlined"
                                fullWidth
                                id="profilePhoto"
                                label="Profile Foto"
                                value={values.profilePhoto}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="urlMenu"
                                variant="outlined"
                                fullWidth
                                id="urlMenu"
                                label="Url del Menu"
                                value={values.urlMenu}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="schedule"
                                variant="outlined"
                                fullWidth
                                id="schedule"
                                label="Horario"
                                value={values.schedule}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="capacity"
                                variant="outlined"
                                fullWidth
                                id="capacity"
                                label="Capacidad del Restaurante"
                                value={values.capacity}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="times"
                                variant="outlined"
                                fullWidth
                                id="times"
                                label="Horarios de reserva"
                                value={values.times}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="subdomain"
                                variant="outlined"
                                fullWidth
                                id="subdomain"
                                label="subdomain"
                                value={values.subdomain}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                id="description"
                                label="Descripcion"
                                value={values.description}
                                inputProps={{
                                    maxLength: CHARACTER_LIMIT
                                }}
                                helperText={`Max Caracters ${CHARACTER_LIMIT}`}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="emailFrom"
                                variant="outlined"
                                fullWidth
                                disabled
                                id="emailFrom"
                                label="E-mail From"
                                value={values.emailFrom}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="emailMessage"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                id="emailMessage"
                                label="E-mail Signature:"
                                value={values.emailMessage}
                                inputProps={{
                                    maxLength: CHARACTER_LIMIT
                                }}
                                helperText={`Max Caracters ${CHARACTER_LIMIT}`}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="street_address"
                                variant="outlined"
                                fullWidth
                                id="street_address"
                                label="Línea de dirección 1 (calle)."
                                value={values.street_address}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="country"
                                name="country"
                                variant="outlined"
                                fullWidth
                                select
                                value={values.country}
                                onChange={handleChange}
                            >
                                {countries.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="region"
                                variant="outlined"
                                fullWidth
                                id="region"
                                label="Estado"
                                value={values.region}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="locality"
                                variant="outlined"
                                fullWidth
                                id="locality"
                                label="Ciudad"
                                value={values.locality}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="postal_code"
                                variant="outlined"
                                fullWidth

                                id="postal_code"
                                label="Codigo Postal"
                                value={values.postal_code}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="latitude"
                                variant="outlined"
                                fullWidth
                                type="number"
                                id="latitude"
                                label="latitude"
                                value={values.latitude}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="longitude"
                                variant="outlined"
                                fullWidth
                                type="number"
                                id="longitude"
                                label="longitude"
                                value={values.longitude}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                    >
                        Guardar
                    </Button>
                </form>
            </Container>
        </div>
    );
}
import React from 'react';
import {
    CssBaseline,
    Grid,
    Typography,
    Box,
    Container,
    Link,
    Avatar,
    Dialog,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';

import Logo from './logo'

import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import ContactForm from "./contactForm";

const useStyles = makeStyles((theme) => ({
    footer: {
        fontSize: 16,
        padding: theme.spacing(6, 0),
        margin: theme.spacing(6, 'auto'),
    },
    separator: {
        height: '2px',
        opacity: '0.2',
        backgroundImage: `linear-gradient(to left, ${theme.palette.primary.main}E6, ${theme.palette.primary.light}CC, ${theme.palette.secondary.light}CC, ${theme.palette.secondary.main}E6)`,
    },
    spanFooter: {
        padding: theme.spacing(0, 0.8),
    },
    divider: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 0.8),
    },
    avatar: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    scrollTop: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}));

export default function Footer() {
    const classes = useStyles();
    const [openForm, setOpenForm] = React.useState(false);
    const link = (event) => {
        event.preventDefault();
        setOpenForm(true);
    };

    return (
        <React.Fragment>
            <CssBaseline/>
            {/* Footer */}
            <div className={classes.separator}/>
            <Container maxWidth="lg" component="footer" className={classes.footer}>
                <Grid container spacing={5} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="h6" color="textPrimary" gutterBottom>
                                    <Logo/>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Link href="https://myappointment.dev/" className={classes.link} underline="none" variant="inherit" color="textPrimary">
                                    Home
                                </Link>
                                <div className={classes.divider}/>
                                <Link href="#" className={classes.link} underline="none" variant="inherit" color="textPrimary" onClick={link}>
                                    Contacto
                                </Link>
                                <Dialog open={openForm} onClose={() => setOpenForm(false)}
                                        aria-labelledby="form-dialog-title">
                                    <ContactForm/>
                                </Dialog>
                                <div className={classes.divider}/>
                                <Link href="https://myappointment.dev/legal/privacy" className={classes.link} underline="none" variant="inherit" color="textPrimary">
                                    Privacy
                                </Link>
                                <div className={classes.divider}/>
                                <Link href="https://myappointment.dev/legal/terms" className={classes.link} underline="none" variant="inherit" color="textPrimary" >
                                    Terms
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    MyAppointment® {new Date().getFullYear()}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    <Link className={classes.link} underline="none" variant="inherit" color="textPrimary" href="mailto:contacto@myappointment.dev">
                                        contacto@myappointment.dev
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    Siguenos en:
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Link href="https://twitter.com/myappointment-dev">
                                    <Avatar className={classes.avatar}>
                                        <TwitterIcon/>
                                    </Avatar>
                                </Link>
                                <span className={classes.spanFooter}/>
                                <Link href="https://www.linkedin.com/myappointment-dev">
                                    <Avatar className={classes.avatar}>
                                        <LinkedInIcon/>
                                    </Avatar>
                                </Link>
                                <span className={classes.spanFooter}/>
                                <Link href="https://www.facebook.com/myappointment-dev">
                                    <Avatar className={classes.avatar}>
                                        <FacebookIcon/>
                                    </Avatar>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
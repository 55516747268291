import React, {useEffect, useReducer} from 'react';
import {
    Link,
    Table,
    TableBody,
    TableRow,
    IconButton,
    Button,
    Grid,
    Typography,
    makeStyles,
    Drawer,
    Collapse,
    TableCell
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import Form from "./formDetails";
import {db} from "../../../services/firebase";
import { dataDetailsInitialStateValues, detailsReducer } from "../../../reducer/reducer/detailsReducer";

const useStyles = makeStyles((theme) => ({

    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(1, 0, 3),
    },
}));

export default function AccountDetails({userId}) {

    const classes = useStyles();

    const [state, dispatch] = useReducer( detailsReducer, dataDetailsInitialStateValues )

    const MerchantDB = db.collection("merchant_data")

    const addOrEditValues = async (linkObject) => {
        try {
            await MerchantDB.doc(userId).update(linkObject);
            dispatch( {
                type: "success",
                payload: linkObject
            } );
            setTimeout(
                () => dispatch( { type: 'closeAlert'} ),
                3000
            );
        } catch (error) {
            console.error(error);
        }
    };

    useEffect ( () => {
        MerchantDB
            .doc( userId )
            .get().then( (doc) => {
            if (doc.exists) {
                dispatch( {
                    type: 'getData',
                    payload: doc.data(),
                } );
            } else {
                MerchantDB
                    .doc( userId )
                    .set(dataDetailsInitialStateValues.data);
                console.log('Document created');

            }
        } ).catch( (error) => {
            dispatch( {type: 'getDataError'} )
        } );
    }, []);


    return (
        <React.Fragment>
            <Collapse in={state.openAlert}>
                <Alert
                    severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => dispatch( { type: 'closeAlert'} )}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    <AlertTitle>Edición Exitosa</AlertTitle>
                    Sus datos han sido editados <strong>exitosamente</strong>
                </Alert>
            </Collapse>
            <Typography component="h3" variant="h4" align="left" color="textPrimary" gutterBottom>
                Detalles de la cuenta
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <Table size="small">
                        <TableBody key={userId}>
                            <TableRow>
                                <TableCell>ID Unico:</TableCell>
                                <TableCell>{userId}</TableCell>
                            </TableRow>
                            {state.data.restaurantName === "" ? null :
                                <TableRow>
                                    <TableCell>Nombre del Restaurante:</TableCell>
                                    <TableCell>{state.data.restaurantName}</TableCell>
                                </TableRow>}
                            {state.data.schedule === "" ? null :
                                <TableRow>
                                    <TableCell>Horario de Atencion:</TableCell>
                                    <TableCell>{state.data.schedule}</TableCell>
                                </TableRow>}
                            {state.data.capacity === "" ? null :
                                <TableRow>
                                    <TableCell>Capacidad del Restaurante:</TableCell>
                                    <TableCell>{state.data.capacity} Sillas</TableCell>
                                </TableRow>}
                            {state.data.times === "" ? null :
                                <TableRow>
                                    <TableCell>Horarios de reserva:</TableCell>
                                    <TableCell>{state.data.times}</TableCell>
                                </TableRow>}
                            {state.data.urlMenu === "" ? null :
                                <TableRow>
                                    <TableCell>Url Menu</TableCell>
                                    <TableCell>
                                        <Link color="secondary" underline="none" href={`${state.data.urlMenu}`}
                                              target="_blank" rel="noreferrer">
                                            Menu
                                        </Link>
                                    </TableCell>
                                </TableRow>}
                            {state.data.url === "" ? null :
                                <TableRow>
                                    <TableCell>Pagina Web:</TableCell>
                                    <TableCell>{state.data.url}</TableCell>
                                </TableRow>}
                            <TableRow>
                                <TableCell>https://cdn.myappointment.dev/p/</TableCell>
                                {state.data.subdomain === "" ?
                                    <TableCell>Editar para obtener Landing Page</TableCell>
                                    :
                                    <TableCell>
                                        <Link color="secondary" underline="none"
                                              href={`https://cdn.myappointment.dev/p/${state.data.subdomain}`} target="_blank"
                                              rel="noreferrer">
                                            {`${state.data.subdomain}`}
                                        </Link>
                                    </TableCell>}
                            </TableRow>
                            {state.data.telephone === "" ? null :
                                <TableRow>
                                    <TableCell>Telefono:</TableCell>
                                    <TableCell>{state.data.telephone}</TableCell>
                                </TableRow>}
                            {state.data.street_address === "" ? null :
                                <TableRow>
                                    <TableCell>Direccion:</TableCell>
                                    <TableCell>{state.data.street_address}</TableCell>
                                </TableRow>}
                            {state.data.locality === "" ? null :
                                <TableRow>
                                    <TableCell>Ciudad:</TableCell>
                                    <TableCell>{state.data.locality}, {state.data.region}</TableCell>
                                </TableRow>}
                            {state.data.postal_code === "" ? null :
                                <TableRow>
                                    <TableCell>Codigo Postal:</TableCell>
                                    <TableCell>{state.data.postal_code}</TableCell>
                                </TableRow>}
                            {state.data.country === "" ? null :
                                <TableRow>
                                    <TableCell>Pais:</TableCell>
                                    <TableCell>{state.data.country}</TableCell>
                                </TableRow>}
                            {state.data.description === "" ? null :
                                <TableRow>
                                    <TableCell>Descripcion:</TableCell>
                                    <TableCell>{state.data.description}</TableCell>
                                </TableRow>}
                            {state.data.emailFrom === "" ? null :
                                <TableRow>
                                    <TableCell>E-mail From:</TableCell>
                                    <TableCell>{state.data.emailFrom}</TableCell>
                                </TableRow>}
                            {state.data.emailMessage === "" ? null :
                                <TableRow>
                                    <TableCell>E-mail Signature:</TableCell>
                                    <TableCell>{state.data.emailMessage}</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Button
                onClick={() => dispatch( { type: 'openEdit'} )}
                variant="contained"
                color="secondary"
                className={classes.submit}>
                Editar
            </Button>
            <Drawer anchor="right" open={state.open} onClose={ () => dispatch( {type: 'closeEdit'} )}>
                <Form data={state.data} {...{addOrEditValues, dispatch}}/>
            </Drawer>
        </React.Fragment>
    );
}

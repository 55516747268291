import React from 'react';
import { auth } from '../services/firebase'
import {
    CssBaseline,
    AppBar,
    Toolbar,
    Button,
    Typography,
    // Link,
    Dialog,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Logo from './logo'
import ContactForm from "./contactForm";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    divider: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(2, 2),
        marginLeft: theme.spacing(2),
    },
    outlineWhiteBtn: {
        borderRadius: '30px',
        color: theme.palette.common.white,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(0, 0.5),
        '&:hover': {
            color: theme.palette.common.white,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
        }
    },
}));


export default function Header() {

    const classes = useStyles();

    const [openForm, setOpenForm] = React.useState(false);
    //
    // const link = (event) => {
    //     event.preventDefault();
    //     setOpenForm(true);
    // }
    // // const linking = (event) => {
    // //     event.preventDefault();
    // // };

    return (
        <React.Fragment>
            <CssBaseline />
            {/* Header */}
            <AppBar position="static" color="default" elevation={0} className={classes.appBar} >
                <Toolbar className={classes.toolbar} id="back-to-top-anchor">
                    <Typography variant="h6" className={classes.toolbarTitle}>
                        <Logo/>
                    </Typography>
                    {/*<nav>*/}
                    {/*    <Link variant="button" underline="none" href="/" className={classes.link}>*/}
                    {/*        Home*/}
                    {/*    </Link>*/}
                    {/*    <Link variant="button" underline="none" href="#" className={classes.link} onClick={linking}>*/}
                    {/*        Precios*/}
                    {/*    </Link>*/}
                    {/*    <Link variant="button" underline="none" href="#" className={classes.link} onClick={linking}>*/}
                    {/*        Blog*/}
                    {/*    </Link>*/}
                    {/*    <Link variant="button" underline="none" href="#" className={classes.link} onClick={link}>*/}
                    {/*        Contacto*/}
                    {/*    </Link>*/}
                    {/*</nav>*/}
                    {/*<div className={classes.divider}/>*/}
                    <Button onClick={() => auth.signOut()} variant="outlined" className={classes.outlineWhiteBtn}>
                        Log Out
                    </Button>
                </Toolbar>
            </AppBar>
            <Dialog open={openForm} onClose={() => setOpenForm(false)} aria-labelledby="form-dialog-title">
                <ContactForm />
            </Dialog>
        </React.Fragment>
    );
}

import React from "react";
// Handle console logs

// ROUTER
import {AuthProvider} from "./router/AuthContext"
import { BrowserRouter } from "react-router-dom"
import Routes from './router/RouterConfig'

// MUI Theme
import { theme } from './styles/muiTheme'
import {ThemeProvider} from "@material-ui/core";


export default function App() {

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
            </AuthProvider>
        </ThemeProvider>
    );
}


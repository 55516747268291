
const dataDetailsInitialStateValues ={
    error: '',
    open: false,
    openAlert: false,
    userId: null,
    data: {
        capacity: "",
        description: "",
        emailFrom: "My Appointment <reserve@myappointment.dev>",
        emailMessage: "Gracias por escoger My Appointment",
        geo: {
            address: {
                country: "",
                locality: "",
                postal_code: "",
                region: "",
                street_address: "",
            },
            latitude: 1,
            longitude: 1,
        },
        profileCoverPhoto: "",
        profilePhoto: "",
        restaurantName: "",
        schedule: "",
        subdomain: "",
        telephone: "",
        times: ["11:00 AM", "12:00 AM", "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM", "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM", "9:00 PM", "10:00 PM"],
        url: "",
        urlMenu: ""
    },
}

const detailsReducer = (state, action) => {

    switch (action.type) {
        case 'userId':
            return {
                userId: action.userId
    }

        case "getData":
            return {
                data: action.payload,
                error: '',
                open: false,
                openAlert: false,
            }

        case "success":
            return {
                data: action.payload,
                error: '',
                open: false,
                openAlert: true,
            }

        case "openEdit":
            return {
                ...state,
                open: true
            }

        case "closeEdit":
            return {
                ...state,
                open: false,
            }

        case "closeAlert":
            return {
                ...state,
                openAlert: false,
            }

        case 'editFormValue':
            state.data[action.key.toLowerCase()] = action.value;
            return { ...state };

        case "getDataError":
            return {
                data: {},
                error: 'Something went wrong!'
            }
        default:
            return state
    }
}

export { dataDetailsInitialStateValues, detailsReducer }